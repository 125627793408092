import React from "react";
import './footer.css'
import image from '../xmb.png'
function Footer() {
    return (
        <footer>
            <div className="userItems">
            <a href='https://shop.alsopss.com' className='icon'>
                <i class="bi bi-shop"></i>
               </a>
               <a href='https://xmb.alsopss.com' className='icon'>
                               <img src={image}></img>
                              </a>
            </div>
        </footer>
    )
}

export default Footer

